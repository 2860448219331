.browser-chrome {
  background-image: url('./images/browsers/chrome.png');
  background-repeat: no-repeat
}

.browser-firefox {
  background-image: url('./images/browsers/firefox.png');
  background-repeat: no-repeat
}

.browser-safari {
  background-image: url('./images/browsers/safari.png');
  background-repeat: no-repeat
}

.browser-edge {
  background-image: url('./images/browsers/microsoft.png');
  background-repeat: no-repeat
}

.browser-opera {
  background-image: url('./images/browsers/opera.png');
  background-repeat: no-repeat
}

.platform-windows {
  background-image: url('./images/OS/windows\ \(1\).png');
  background-repeat: no-repeat
}

.platform-ios, .platform-mac {
  background-image: url('./images/OS/apple-logo.png');
  background-repeat: no-repeat
}

.platform-linux {
  background-image: url('./images/OS/linux\ \(1\).png');
  background-repeat: no-repeat
}

.platform-ubuntu {
  background-image: url('./images/OS/ubuntu.png');
  background-repeat: no-repeat
}
.platform-android {
  background-image: url('./images/OS/android.png');
  background-repeat: no-repeat
}