.zc-input-container{
  position: relative;
  width: 100%;
}
.zc-icon-input{
  width: 35px;
  height: 35px;
  position: absolute;
  top: calc(50% - 17.5px);
  left: 10px;
}
.zc-input{
  width: calc(100% - 65px);
  color: #fff;
  padding: 18.5px 15px 18.5px 50px;
  font-size: var(--fourth-font-size);
  border-radius: 10px;
  border: none;
  background: #AAB8C1;
}