.zc-search-input{
  position: relative;
  width: 100%;
}

.zc-icon-container {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  color: #555;
  margin-right: 10px;
}
.zc-icon-container.right {
  left: unset;
  right: 0px;
  cursor: pointer;
}
.zc-input-styled {
  padding: 10px 40px 10px 40px;
  font-size: var(--fourth-font-size);
  border-radius: 4px;
  width: 100%;
  background: transparent;
  border: 1px solid var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}
.zc-input-styled::placeholder {
  opacity: 0.7;
}

@media (max-width: 950px) {
  .zc-icon-container {
    top: calc(50% - 8px);
  }
  .zc-icon-container svg {
    width: 16px;
    height: 16px;
  }
  .zc-input-styled {
    padding: 5px 27px 5px 27px;
    font-size: var(--second-font-size);
  }
  .zc-input-styled::placeholder {
    font-size: var(--second-font-size);
  }
}