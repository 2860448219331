.zc-simple-slect {
  width: 100%;
  height: 50px;
  border: 1px solid #CCC;
  border-radius: 4px;
  padding: 0px 5px;
  font-size: 15px;
}
.zc-simple-slect::before,
.zc-simple-slect::after {
  border: none !important;
}