.zc-color-picker-input-container{
  display: flex;
  align-items: center !important;
  gap: 5px;
}
.zc-color-picker-input{
  width: 35px;
  height: 35px;
  padding: 0px;
}
.zc-color-picker-input-container div {
  color: #1b1b1b;
  font-size: var(--second-font-size);
  font-weight: var(--wider-font-weight);
}