@font-face {
  font-family: "IRANSansX-light";
  font-style: normal;
  font-weight: 300;
  src: url('fonts/woff2/IRANSansX-Light.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-black";
  font-style: normal;
  font-weight: 500;
  src: url('fonts/woff2/IRANSansX-Black.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-bold";
  font-style: normal;
  font-weight: 600;
  src: url('fonts/woff2/IRANSansX-Bold.woff2') format('woff2')
}


@font-face {
  font-family: "IRANSansX-demiBold";
  font-style: normal;
  font-weight: 600;
  src: url('fonts/woff2/IRANSansX-DemiBold.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-extraBold";
  font-style: normal;
  font-weight: 600;
  src: url('fonts/woff2/IRANSansX-ExtraBold.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-medium";
  font-style: normal;
  font-weight: 500;
  src: url('fonts/woff2/IRANSansX-Medium.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-regular";
  font-style: normal;
  font-weight: 300;
  src: url('fonts/woff2/IRANSansX-Regular.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-thin";
  font-style: normal;
  font-weight: 400;
  src: url('fonts/woff2/IRANSansX-Thin.woff2') format('woff2')
}

@font-face {
  font-family: "IRANSansX-ultraLight";
  font-style: normal;
  font-weight: 300;
  src: url('fonts/woff2/IRANSansX-UltraLight.woff2') format('woff2')
}

body {
  margin: 0;
  font-family: 'IRANSansX-ultraLight', 'IRANSansX-thin', 'IRANSansX-regular', 'IRANSansX-medium',
  'IRANSansX-extraBold', 'IRANSansX-demiBold', 'IRANSansX-bold', 'IRANSansX-black', 'IRANSansX-light',
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3c4858;
}

.App{
  /* border: 2px solid !important; */
}

textarea{
  font-family: 'IRANSansX-medium';
}

h4 {
  margin: 10px !important;
}

.d-flex {
  display: flex;
  gap: 10px;
}

.MuiDialogTitle-root {
  padding: 16px 0px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5);
}

.MuiButtonBase-root {
  margin: 0px;
}

@media  screen and (max-width:950px) {
  .MuiButton-label{
    font-size: 12px;
  }
  .MuiButtonBase-root {
    min-width: 50px!important;
  }
  .MuiAccordionSummary-root{
    min-height: 35px;
  }
  .MuiAccordionSummary-root.Mui-expanded{
    min-height: 35px;
  }
  .MuiListItem-gutters{
    font-size: 12px;
  }
  .MuiTableRow-root{
    height: 35px;
  }
}

.MuiTouchRipple-ripplePulsate{
  display: none !important;
}

@keyframes statusAnimation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.MuiDialogContent-root {
  padding: 0px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiAccordion-root.Mui-expanded  {
  margin: 0px;
}

* {
  outline: none;
  text-decoration: none;
}

.MuiButton-root, .MuiTab-wrapper {
  text-transform: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
}
.assign-admin{
  width: 110px!important;
}
.chat-limit-select{
  width: 40%!important;
  border: unset!important;
  border-radius: unset!important;
  border-bottom: 1px solid #888!important;
  height: 20px!important;
  font-size: 13px!important;
}
.widget-simple-select{
  width: 100px!important;
  font-size: 16px;
  background-color: white;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}
@media screen and (max-width:950px) {
  .svg_expander{
    width:16px !important;
    height: 16px !important;
  }
}